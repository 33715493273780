
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import useEmitter from '@/composables/useEmitter'
// import { VueTelInput } from "vue3-tel-input";
// import "vue3-tel-input/dist/vue3-tel-input.css";
export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
    // VueTelInput,
  },
  setup() {
    const phone = ref(null);
    const emitter = useEmitter()
    const formData = ref({
      name: "",
      email: "",
      number: "",
      timezone: "",
    });
    const numberList = ref([]);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const profile = ref<any>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    const validNumber = ref<any>();
    const phone_number = ref<any>("");
    const page = ref<string>("");
    const inputOptions = ref<any>({
      styleClasses: "form-control form-control-lg form-control-solid",
    });
    const dataAll = ref<any>();
    const userDataAll = ref("");
    // const value = ref('');
    const registration = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      email: Yup.string().min(4).required().email().label("Email"),
      number: Yup.number()
        .typeError("Please Speacify a number")
        .required()
        .label("Number"),
      timezone: Yup.string().required().label("Timezone"),
    });

    onMounted(() => {
      const userData = localStorage.getItem("userdata");
      var allData = JSON.parse("" + userData + "");

      // console.log('allData',allData.number);
      dataAll.value = allData;
      if (allData.type == "user") {
        phone_number.value = allData.number;
        getUser();
        setCurrentPageBreadcrumbs("My Profile", []);
        store.dispatch(Actions.GET_AUTH_USER).then((data) => {
          profile.value = data;
        });
        nextTick(() => {
          PasswordMeterComponent.bootstrap();
        });
      } else {
        router.push({ name: "dashbord" });
      }
      getTimezone();
    });
    const getUser = () => {
      var id = dataAll.value._id;
      var request = {
        url: `oneuser/${id}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // console.log(data)
          formData.value = {
            name: data.data.name,
            email: data.data.email,
            number: data.data.number,
            timezone: data.data.timezone,
          };
        }
      });
    };

    const testFunction = (event, phoneObject) => {
      if (phoneObject !== undefined) {
        // console.log(event);
        // console.log(phoneObject);
        validNumber.value = phoneObject;
        if (phoneObject.valid) {
          // console.log('nd');
          formData.value.number = phoneObject.number;
        } else {
          formData.value.number = "";
        }
      }
    };
    const onSubmitRegister = (values, { resetForm }) => {
      submitButton.value!.disabled = true;
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      const userData = localStorage.getItem("userdata");
      var allData = JSON.parse("" + userData + "");
      console.log("values", values.name);
      
      if (values.name.trim() == "") {
        Swal.fire({
          text: "Please enter the name!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.number.length > 10 || values.number.length < 10) {
        Swal.fire({
          text: "Please enter the 10 digits number",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else {
        loading.value = true;
        var id = allData._id;
        var request = {
          url: `profile-update/${id}`,
          data: {
            name: values.name,
            email: values.email,
            number: values.number,
            timezone: values.timezone,
          },
        };
        store
          .dispatch(Actions.PUT, request)
          .then((data) => {
            // router.push({ name: "admin-user" });
            if (data) {
              localStorage.setItem("userdata", JSON.stringify(data.data));
              emitter.emit("toggle-sidebar", 'test');
              // router.push({ name: "dashboard" });
              // Swal.fire("Success", "Profile updated successfully!", "success");
              Swal.fire({
                text: "Profile updated successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                //localStorage.setItem('userdata', JSON.stringify(data));
                // setTimeout(() =>{
                //     location.reload();
                // }, 1000)
                //localStorage.setItem("userdata", JSON.stringify(data.data));
                //location.reload();
              });
            }
            loading.value = false;
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
        submitButton.value!.disabled = false;
      // }, 2000);
      // Swal.fire({
      //   text: "User added successfully!",
      //   icon: "success",
      //   buttonsStyling: false,
      //   confirmButtonText: "Ok, got it!",
      //   customClass: {
      //     confirmButton: "btn fw-bold btn-light-primary",
      //   },
      // }).then(function () {
      //   router.push({ name: "admin-user" });
      // });
    };

    const getTimezone = () => {
      var request = {
        url: `timezone/get`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // console.log(data)
          numberList.value = data.data;
        }
      });
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      phone,
      testFunction,
      validNumber,
      phone_number,
      formData,
      inputOptions,
      page,
      getUser,
      userDataAll,
      dataAll,
      numberList,
    };
  },
});
